﻿/**
全局变量和函数定义
*/

// Core variables and mixins
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';


@mixin box-center($w, $h: $w) {
  position: absolute;
  width: $w;
  height: $h;
  top: 50%;
  left: 50%;
  margin: (-$h / 2) 0 0 (-$w / 2);
}

@mixin bg-dif($repeat: no-repeat, $position: center center, $size: cover) {
  background-repeat: $repeat;
  background-position: $position;
  background-size: $size;
}

@mixin opacity-on($true: true) {
  @include transition(.5s all ease);

  @if $true {
    opacity: 0;
    pointer-events: none;

    &.on {
      opacity: 1;
      pointer-events: all;
    }
  }

  @else {
    opacity: 1;

    &.on {
      opacity: 0;
      pointer-events: none;
    }
  }
}

@mixin display-on() {
  display: none;

  &.on {
    display: block;
  }
}

@mixin closeX($height) {
  position: relative;

  &:before, &:after {
    content: '';
    background-color: #fff;
    @include box-center(1px, $height);
  }

  &:before {
    @include transform(rotate(45deg));
  }

  &:after {
    @include transform(rotate(-45deg));
  }
}

@mixin divPosition($w: 100%, $h: $w) {
  position: absolute;
  left: 0;
  top: 0;
  width: $w;
  height: $h;
}

@mixin extendClickArea($x, $y:$x) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -$x;
    left: -$y;
    bottom: -$x;
    right: -$y;
  }
}

@mixin lantern-img($fdtime:.5s, $sdtime:.5s) {
  img:nth-child(1) {
    @include animation(bounceInDown 1s ease $fdtime);
    animation-fill-mode: forwards;
    opacity: 0;
  }

  img:nth-child(2) {
    @include animation(bounceInDown 1s ease $sdtime);
    animation-fill-mode: forwards;
    opacity: 0;
  }
}

@mixin zoomIn($etime:.5s, $dtime:.7s) {
  transform: scale(.3);
  transition: $etime all cubic-bezier(.25, .1, .65, 1.65) $dtime;
  opacity: 0;
}

@mixin size($w, $h:$w) {
  width: $w;
  height: $h
}

@mixin iphonePaddingBottom() {
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
}