/**
全局复写通用样式
*/

@import 'variables';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  // user-select: none;
  // -webkit-user-select: none;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

$fnwColor: #96bc32;
// html {
//   // font-size: 10px;
//   // min-height: 100%;
//   // scroll-behavior: smooth;

//   body {
//     margin: 0;
//     padding: 0;
//     background-color: #f2f2f2;
//     color: #000;
//     min-height: 100vh;
//     // overflow: hidden;
//   }
// }

input,
textarea,
button,
select {
  appearance: none;
  // background: none;
  border: none;
  outline: none;
}

textarea {
  resize: none;
  // width: 100%;
}

// a {
//   // display: inline-block;
//   // text-decoration: none;
// }

// img {
//   max-width: 100%;
//   max-height: 100%;
//   pointer-events: none;
// }

// input {
//   background: none;
//   border: none;
//   // user-select: auto;
//   // -webkit-user-select: auto;
// }

// video,
// canvas,
// svg {
//   display: block;
// }
.tishi {
  color: #000;
  font-size: 12px;
  margin: 0 auto;
}
ul,
ol,
dl,
dd {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-layout {
  height: auto !important;
  min-height: 100vh;
}
.ant-input {
  // 修复input行高
  line-height: 1.5715;
}

.ant-page-header {
  padding: 0;
}

.ant-tree-treenode-selected {
  background-color: #bae7ff;
  margin: 0 0 4px 0;
  padding: 0 !important;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.extendClickArea {
  @include extendClickArea(10px);
}

.red {
  background-color: #ffeeee !important;
}

.green {
  background-color: #ebfbe3 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: $fnwColor;
  border-color: $fnwColor;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $fnwColor;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $fnwColor;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $fnwColor;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: $fnwColor;
}

.ant-steps-item-process,
.ant-steps-item-error,
.ant-steps-item-wait {
  > .ant-steps-item-container > .ant-steps-item-tail::after {
    background: rgba(0, 0, 0, 0.15);
  }
}

.ant-steps-item-process,
.ant-steps-item-error,
.ant-steps-item-wait {
  > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: rgba(0, 0, 0, 0.15);
  }
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}

body.iics {
  .ant-message {
    display: none;
  }
}
.ant-layout-sider,
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  background: #000;
  color: #fff;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #fff;
  background: rgb(213, 0, 28);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #fff;
}
.ant-layout {
  background: rgb(218, 217, 222);
}
.ant-btn-dangerous {
  color: #fff;
  background: #000;
  border: #000;
  &:hover {
    color: #fff;
    background: #000;
    border: #000;
  }
}
.ant-btn-primary,
.login-form-button {
  color: #fff;
  background: #000;
  border: #000;
  &:hover {
    color: #fff;
    background: #000;
    border: #000;
  }
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #000;
  border: #000;
}
@font-face {
  font-family: 'porsche';
  src: url('../assets/PorscheNext-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'porsche', 'Microsoft YaHei', '微软雅黑' !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(0, 86, 112);
  &:hover {
    color: rgb(0, 86, 112);
  }
}
.ant-tabs-ink-bar {
  background: rgb(0, 86, 112);
}
