/**
全局变量定义
*/

@import './mixins';

$font-family-sans-serif: // Safari for OS X and iOS (San Francisco)
  -apple-system,
  // Chrome >= 56 for OS X (San Francisco), Windows, Linux and Android
  system-ui,
  // Chrome < 56 for OS X (San Francisco)
  BlinkMacSystemFont,
  // Windows
  'Segoe UI',
  // Android
  'Roboto',
  // Basic web fallback
  'Microsoft YaHei',
  'Helvetica Neue', Arial, sans-serif;

// 灰色文字颜色
$color-main: #88c1d2;
$color-btn-blue: #424fff;
$color-btn-green: #53bb9a;
$color-btn-red: #dc7281;

$color-bg-main: #fff;
$color-bg-gray: #f2f2f2;
$color-bg-msg: #c2cbff;
$color-bg-border: #ddd;

$color-text-help: #999;
$color-text-gray: #666;
$color-title-bg: #5aa4ba;

// 前线背景绿
$fnwColor: #96bc32;
$background-main: $fnwColor;
