@import './ant.less';
// 复写ant样式

// @layout-header-background: @blue-6;

// @menu-dark-submenu-bg: @blue-7;

// 复写tree结构
@tree-title-height: 34px;
.ant-tree {
  .ant-tree-switcher {
    width: 14px;
  }
}

.ant-transfer-customize-list .ant-transfer-list {
  flex: 12;
}

.transfer {
  .ant-transfer-list[direction='left'] {
    background: #eee;
  }
}

.diff {
  background-color: #eff4ff;
}

.ant-form-item {
  width: 100%;
}
