/**
全局组件通用样式，如果组件数量过多注意拆分文件
*/

.red-text{
    color: rgb(0, 86, 112);
  }
  .center{
      text-align: center;
  }
  .ant-upload-list-item{
    display: none;
  }